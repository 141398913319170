import { Mdx } from "@GraphQLModels";

export class PedalMaker {
  constructor(
    public route: string,
    public name: string,
    public mainImage: string) {
  }


  public static fromMdxNode(node: Mdx): PedalMaker {
    return new PedalMaker(
      node.fields.route,
      node.frontmatter.title,
      node.frontmatter.mainImage
    );
  }
}
