import * as React from "react";
import { Link, StaticQuery } from "gatsby"
import { childClassClosure } from "@Functions";
import "./SiteHeader.scss";
import SearchBox from "components/search/SearchBox";
import { graphql } from "gatsby"
import { SiteSearchIndex } from "@GraphQLModels";
import LinkWrapper from "components/texty/LinkWrapper";

const baseClass = 'site-header';
const childClass = childClassClosure(baseClass);

interface StaticQueryData {
  siteSearchIndex: SiteSearchIndex
}

const SiteHeader: React.SFC<{}> = () => {
  return <StaticQuery
    query={headerQuery}
    render={(data: StaticQueryData) => (
      <header className={baseClass}>
        <div className={childClass('container')}>
          <div className={childClass('homelink')}>
            <LinkWrapper to="/">
              <img src="/assets/media/logo.png" alt="Pedal Dudes" />
            </LinkWrapper>
          </div>
          <nav className={childClass('menu')}>
            <ul>
              <li><LinkWrapper to="/all-articles" className={childClass('link')}>Articles</LinkWrapper></li>
              <li><LinkWrapper to="/all-reviews" className={childClass('link')}>Reviews</LinkWrapper></li>
              <li><LinkWrapper to="/browse-pedals" className={childClass('link')}>Browse</LinkWrapper></li>
              <li><SearchBox searchIndex={data.siteSearchIndex.index} /></li>
            </ul>
          </nav>
        </div>
      </header>
    )}
  />
}

export default SiteHeader;


const headerQuery = graphql`
      query SearchIndexQuery {
        siteSearchIndex {
          index
        }
    }
  `
