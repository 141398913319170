import { Mdx } from "@GraphQLModels";

export class ArticleMetaData {
  constructor(
    public route: string,
    public title: string,
    public standfirst: string,
    public date: Date | null,
    public mainImage: string) {
  }

  public static from(node: Mdx): ArticleMetaData {
    return new ArticleMetaData(
      node.fields.route,
      node.frontmatter.title,
      node.frontmatter.standfirst,
      new Date(node.frontmatter.date),
      node.frontmatter.mainImage);
  }
}
