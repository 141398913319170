import { Mdx } from "@GraphQLModels";

export class PedalCategory {
  constructor(
    public route: string,
    public name: string,
    public mainImage: string) {
  }

  public static fromMdxNode(node: Mdx): PedalCategory {
    return new PedalCategory(
      node.fields.route,
      node.frontmatter.title,
      node.frontmatter.mainImage
    );
  }
}

export function pedalCategorySortComparer(a: PedalCategory, b: PedalCategory) {
  if (a.name == b.name) return 0;

  const priorityOrder = ['boost', 'overdrive', 'distortion', 'bass-pedals', 'delay', 'compressors'];
  const indexA = priorityOrder.indexOf(a.route.substring(1));
  const indexB = priorityOrder.indexOf(b.route.substring(1));
  if (indexA >= 0 && indexB < 0) return -1;
  if (indexB >= 0 && indexA < 0) return 1;
  if (indexA >= 0 && indexB >= 0) return indexA > indexB ? 1 : -1;
  return a.name.localeCompare(b.name);
}
