import * as React from 'react';
import './SearchSuggestions.scss';
import { childClassClosure, baseClassAnd, isNullOrEmpty } from '@Functions';
import { ElasticLunrSearchResult, ArticleMetaData, Pedal } from "@ViewModels";
import { Link } from 'gatsby';
import ArticleLinkCard from 'components/cards/ArticleLinkCard';
import LinkWrapper from 'components/texty/LinkWrapper';

const baseClass = 'search-suggestions';
const childClass = childClassClosure(baseClass);

interface IProps {
  query: string;
  results: ElasticLunrSearchResult[]
  className?: string
}

function isPedalResult(result: ElasticLunrSearchResult) {
  return isNullOrEmpty(result.templateKey) && result.route.split('/').length == 3;
}


const PedalResultRow: React.SFC<{ pedal: Pedal }> = (props: { pedal: Pedal }) => {
  return <li className={childClass('item', 'pedal')}>
    <LinkWrapper to={props.pedal.route} className={childClass('pedal-result')} >
      <div className={childClass('pedal-result-image-wrapper')}>
        <img src={props.pedal.mainImage} alt={props.pedal.name} />
      </div>
      <div className={childClass('pedal-result-content-wrapper')}>
        <h5 className={childClass('pedal-result-title')}>{props.pedal.name}</h5>
        <div className={childClass('pedal-result-maker-image-wrapper')}>
          <img src={props.pedal.makerImageUrl} alt={props.pedal.makerSlug} />
        </div>
      </div>
    </LinkWrapper>
  </li>
}


const SearchSuggestionsBox: React.SFC<IProps> = (props: IProps) => {

  return <ul className={baseClassAnd(baseClass, props)} >
    {!isNullOrEmpty(props.query) && props.results.length == 0 && <li className={childClass('item', 'no-results')}>No results for "{props.query}"</li>}
    {props.results.some(x => x.templateKey == 'article-content') && <li className={childClass('item', 'heading')}>Articles</li>}
    {props.results.filter(x => x.templateKey == 'article-content').map(page => (
      <li className={childClass('item', 'article')} key={page.id}>
        <LinkWrapper to={page.route} className={childClass('article-result')} >
          <div className={childClass('article-result-image-wrapper')}>
            <img src={page.mainImage} alt={page.title} />
          </div>
          <div className={childClass('article-result-content-wrapper')}>
            <h5 className={childClass('article-result-title')}>{page.title}</h5>
            <p className={childClass('article-result-standfirst')}>{page.standfirst}</p>
          </div>
        </LinkWrapper>
      </li>
    ))}
    {props.results.some(x => isPedalResult(x)) && <li className={childClass('item', 'heading')}>Pedals</li>}
    {props.results.filter(x => isPedalResult(x)).map(page => <PedalResultRow key={page.id} pedal={Pedal.fromElasticLunrSearchResult(page)} />)}
  </ul >
}
export default SearchSuggestionsBox;
