import * as React from "react";
import { childClassClosure, isNullOrEmpty } from "@Functions";
import "./SearchBox.scss";
import bind from "bind-decorator";
import { SearchGlassSvg } from "@Svg";
import { Index } from "elasticlunr";
import { Link } from "gatsby";
import { SiteSearchIndex } from "@GraphQLModels";
import { ElasticLunrSearchResult } from "@ViewModels";
import SearchSuggestionsBox from "./SearchSuggestions";

const baseClass = 'search-box';
const childClass = childClassClosure(baseClass);

interface IProps {
  searchIndex: SiteSearchIndex
}

interface IState {
  query: string,
  results: ElasticLunrSearchResult[]
}


export default class SearchBox extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      query: '',
      results: []
    }
  }

  inputRef: React.RefObject<HTMLInputElement>;
  index?: any;

  @bind
  clickMobileSearchButton() {
    this.inputRef.current.focus();
  }

  @bind
  getOrCreateIndex() {
    return this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
      Index.load(this.props.searchIndex)
  };

  @bind
  clearSearch() {
    window.onscroll = () => { }; // TODO this is a really dirty way to stop scrolling
    this.setState({ query: '', results: [] });
  }

  componentWillUnmount() {
    window.onscroll = () => { };
  }

  @bind
  onChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

    const query = e.target.value;
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, {})
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
    if (!isNullOrEmpty(query)) {
      var x = window.scrollX;
      var y = window.scrollY;
      window.onscroll = () => window.scrollTo(x, y); // TODO this is a really dirty way to stop scrolling
    }
  }

  @bind
  onKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.charCode == 13) {
      this.inputRef.current.blur();
    }
  }

  public render() {
    return [<div className={baseClass} onClick={this.clickMobileSearchButton} key={0}>
      <input ref={this.inputRef} className={childClass('input')} type="text" onChange={this.onChangeInput} value={this.state.query} placeholder='Search' onKeyPress={this.onKeyPress} />
      <SearchGlassSvg className={childClass('icon')} />
    </div>,
    <div className={childClass('suggestions-container', this.state.results.length > 0 || !isNullOrEmpty(this.state.query) ? 'open' : 'hidden')} key={1} onClick={this.clearSearch}>
      <SearchSuggestionsBox results={this.state.results} query={this.state.query} className={childClass('suggestions')} />
    </div>]
  }
}
