import { Mdx, File as GraphQLFile, Fields_2 } from "@GraphQLModels";
import { isNullOrEmpty, removeTrailingSlash } from "@Functions";
import { ElasticLunrSearchResult } from "@ViewModels";

export class Pedal {
  constructor(
    public route: string,
    public name: string,
    public makerSlug: string,
    public mainImage: string) {
  }

  public get makerImageUrl() {
    return `/assets/media/${this.makerSlug}.png`;
  }

  public static extractManufacturerFromRoute(route: string) {
    const routeSections = route.split('/').filter(x => !isNullOrEmpty(x));
    return routeSections[0];
  }

  public static fromMdxNode(node: Mdx): Pedal {
    return new Pedal(
      node.fields.route,
      node.frontmatter.title,
      Pedal.extractManufacturerFromRoute(node.fields.route),
      `/assets/pedals${removeTrailingSlash(node.fields.route)}-1.png`);
  }

  public static fromFileNode(fileNode: GraphQLFile): Pedal {
    const route = `/${fileNode.relativeDirectory}/${fileNode.name}/`;
    return new Pedal(
      route,
      fileNode.childMdx ? fileNode.childMdx.frontmatter.title : fileNode.name,
      fileNode.relativeDirectory,
      `/assets/pedals${removeTrailingSlash(route)}-1.png`);
  }


  public static fromElasticLunrSearchResult(result: ElasticLunrSearchResult): Pedal {
    return new Pedal(
      result.route,
      result.title,
      Pedal.extractManufacturerFromRoute(result.route),
      `/assets/pedals${result.route}-1.png`);
  }

}
