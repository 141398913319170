import { Pedal } from "@ViewModels";
import { BuyLinks_2 } from "@GraphQLModels";
import { isNullOrEmpty, ensureTrailingSlash } from "@Functions";

export class BuyLinkCollection {
  constructor(
    public title: string,
    public pedals: Pedal[]) {
  }

  public static splitFrontmatterBuyLinkString(frontmatterBuyLinkString: string) {
    return frontmatterBuyLinkString.split(' ').map(url => ensureTrailingSlash(url[0] === '/' ? url : '/' + url));
  }

  public static fromMdxMeta(buyLink: BuyLinks_2, pedals: Pedal[]) {
    if (isNullOrEmpty(buyLink.idList)) {
      return undefined;
    }
    const buyLinkRoutes = BuyLinkCollection.splitFrontmatterBuyLinkString(buyLink.idList);
    const matchingPedals = pedals.filter(x => buyLinkRoutes.includes(x.route));
    return new BuyLinkCollection(buyLink.title, matchingPedals);
  }

  public static fromIdListString(title: string, idListString: string, pedals: Pedal[]) {
    if (isNullOrEmpty(idListString)) {
      return undefined;
    }
    const buyLinkRoutes = BuyLinkCollection.splitFrontmatterBuyLinkString(idListString);
    const matchingPedals = pedals.filter(x => buyLinkRoutes.includes(x.route));
    return new BuyLinkCollection(title, matchingPedals);
  }

}
